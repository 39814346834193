import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Logo from '@/assets/Logo';
import LoginNavbarButton from './LoginNavbarButton';
import LoginIcon from '@/assets/LoginIcon';
import SignUpIcon from '@/assets/SignUpIcon';
import CountrySwitcher from './CountrySwitcher';
import LogoWithoutText from '@/assets/LogoWithoutText';
import ShareNavIcon from '@/assets/ShareNavIcon';
import MessageNavIcon from '@/assets/MessageNavIcon';
import SettingsNavIcon from '@/assets/SettingsNavIcon';
import AvatarDefault from '../assets/avatarPhoto.webp';
import SearchInput from './SearchInput';
import { getCookie } from '@/utils/helpFunctions';
import { getMyDataApi } from '@/utils/user/getMyDataApi';

function Navbar() {
  const router = useRouter();
  const [image, setImage] = useState("");
  const [userId, setUserId] = useState<string | null>(null);
  const [language, setLng] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  // Pobranie danych użytkownika, jeśli istnieje cookie userId
  useEffect(() => {
    const cookieUserId = getCookie("userId");
    const cookieLanguage = getCookie("language");
    if (cookieUserId) {
      setUserId(cookieUserId);
      setLng(cookieLanguage);
      const handler = async () => {
        const result = await getMyDataApi();
        if (result.success) {
          setImage(result.data.imageUrl || "");
        }
        setIsLoading(false);
      };
      handler();
    } else {
      setIsLoading(false);
    }
  }, []);

  // Aktualizacja ciasteczek co sekundę (opcjonalnie, zależnie od potrzeb)
  useEffect(() => {
    const interval = setInterval(() => {
      const newUserId = getCookie("userId");
      const newLanguage = getCookie("language");
      setUserId((prev) => (prev !== newUserId ? newUserId : prev));
      setLng((prev) => (prev !== newLanguage ? newLanguage : prev));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const loginHandler = () => {
    router.push("/login");
  };

  const signUpHandler = () => {
    router.push("/register");
  };

  const profileHandler = () => {
    const currentUserId = getCookie("userId");
    router.push(`/profile/${currentUserId}`);
  };

  // Nie renderuj, dopóki router nie jest gotowy lub trwa ładowanie danych
  if (!router.isReady || isLoading) {
    return <nav className="fixed top-0 left-0 right-0 py-4 h-16 flex items-center justify-between px-4 xl:px-8 lg:px-3 bg-base-100 shadow-md border-b border-[#C9C6C6]"/>; // Możesz tutaj zwrócić loader, jeśli chcesz
  }

  return (
    <nav className="fixed top-0 left-0 right-0 py-4 h-16 z-50 flex items-center justify-between px-4 xl:px-8 lg:px-3 bg-base-100 shadow-md border-b border-[#C9C6C6]">
        { userId ? ( 
          <>
            <div className="flex items-center xl:w-2/5 w-2/5 lg:w-2/4">
              <div className="flex lg:hidden pr-2">
                <a href="/home" aria-label="go to home page" className='flex'>
                  <LogoWithoutText />
                </a>
              </div>
              <div className="lg:flex hidden pr-2">
                <a href="/home" aria-label="go to home page" className='flex'>
                  <Logo />
                </a>
              </div>
              <SearchInput />
            </div>

            <div className="flex items-center space-x-4">
              <CountrySwitcher />
              <div className="flex items-center space-x-3">
                <a
                  href="#"
                  aria-label="share info"
                  className="flex items-center justify-center w-10 h-10 text-blue-500 hover:text-blue-700"
                >
                  <ShareNavIcon />
                </a>
                <a
                  href="#"
                  aria-label="message someone"
                  className="flex items-center justify-center w-10 h-10 text-blue-500 hover:text-blue-700"
                >
                  <MessageNavIcon />
                </a>
                <a
                  href="/control-panel/user-profile"
                  aria-label="go to settings page"
                  className="flex items-center justify-center w-10 h-10 text-blue-500 hover:text-blue-700"
                >
                  <SettingsNavIcon />
                </a>
                <img
                  src={image || AvatarDefault.src}
                  alt="User avatar"
                  className="w-14 h-14 drop-shadow-md cursor-pointer rounded-full border border-gray-300"
                  onClick={profileHandler}
                />
              </div>
            </div>
        </>
    ) : (
      // <nav className="py-4 flex items-center justify-between px-4 xl:px-8 lg:px-3 bg-base-100 shadow-md border-b border-[#C9C6C6]">
      <>
        <div>
          <a href="/" aria-label="go to home page" className='flex'>
            <Logo />
          </a>
        </div>
        <div className="flex gap-3">
          <CountrySwitcher />
          <LoginNavbarButton text="Login" onClick={loginHandler} Icon={LoginIcon} />
          <LoginNavbarButton text="Sign up" onClick={signUpHandler} Icon={SignUpIcon} />
        </div>
      </>
    )}
    </nav>
  );
}

export default Navbar;
