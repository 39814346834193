import { ReactNode } from 'react';
import { useRouter } from 'next/router';

import Footer from './Footer';
import Navbar from './Navbar';
import { countries } from '@/locales';

type AppWrapperType = {
  children: ReactNode;
};

const AppWrapper = ({ children }: AppWrapperType) => {
  const router = useRouter();
  const currentPath = router.asPath;

  const hideFooter = countries.some(
    (country: any) =>
      currentPath === `/${country.code}` || currentPath === `/${country.code}/`
  );

  return (
    <div>
      <Navbar />
      <main className="pt-24">
        {router.isReady ? (
          children
        ) : (
          <div className='text-center w-full py-12'>
            <span className="loading loading-dots loading-lg"></span>
          </div>
        )}
      </main>
      {router.isReady ? 
          !hideFooter && <Footer />
        :
        null
      }
    </div>
  );
};

export default AppWrapper;
