import * as React from "react";

const SvgIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    fill="none"
    viewBox="0 0 40 40"
  >
    <path
      fill="#4988CE"
      d="M18.16 31.5q-.406 0-.704-.274a1.12 1.12 0 0 1-.359-.68l-.352-2.733a5.6 5.6 0 0 1-.997-.457 6.4 6.4 0 0 1-.914-.628l-2.528 1.066q-.389.17-.772.04a1.1 1.1 0 0 1-.601-.49l-1.82-3.17a1.07 1.07 0 0 1-.129-.766q.08-.407.408-.656l2.188-1.655a6 6 0 0 1-.06-.543 9 9 0 0 1 0-1.067q.018-.267.06-.583l-2.188-1.656a1.07 1.07 0 0 1-.414-.656 1.02 1.02 0 0 1 .134-.766l1.82-3.147q.219-.359.597-.49a1.02 1.02 0 0 1 .765.04l2.529 1.055q.437-.347.934-.634t.977-.463l.363-2.733q.06-.407.358-.68.299-.274.706-.274h3.666q.408 0 .711.274.305.273.365.68l.351 2.745q.545.198.986.462t.89.623l2.575-1.055a1.04 1.04 0 0 1 .766-.045q.377.125.596.483l1.82 3.159q.22.358.134.766-.084.407-.413.656l-2.235 1.69q.066.294.072.549.005.254.005.513 0 .247-.011.502-.012.255-.084.583l2.211 1.667q.33.25.414.656.084.408-.134.766l-1.82 3.152a1.12 1.12 0 0 1-.612.492q-.392.134-.78-.036l-2.503-1.066q-.45.359-.918.634a5.5 5.5 0 0 1-.959.45l-.351 2.746q-.06.407-.364.68t-.712.274zm.63-1.816h2.379l.435-3.243a6.7 6.7 0 0 0 1.693-.687 7.5 7.5 0 0 0 1.48-1.147l3.008 1.264 1.192-2.058-2.626-1.979q.151-.47.206-.921a7.6 7.6 0 0 0 0-1.826 4.7 4.7 0 0 0-.206-.898L29 16.187l-1.192-2.058-3.043 1.283a6.4 6.4 0 0 0-1.456-1.148 6.3 6.3 0 0 0-1.717-.705l-.381-3.243h-2.403l-.4 3.23a6.3 6.3 0 0 0-1.711.671 7.2 7.2 0 0 0-1.497 1.164l-3.008-1.252L11 16.187l2.614 1.948q-.15.43-.212.897a7.6 7.6 0 0 0 0 1.906q.061.455.2.896L11 23.814l1.192 2.057 2.996-1.271q.689.708 1.474 1.16a6.9 6.9 0 0 0 1.734.693zm1.224-6.052q1.51 0 2.571-1.06 1.06-1.062 1.06-2.572t-1.06-2.571q-1.06-1.06-2.571-1.06-1.53 0-2.58 1.06-1.052 1.06-1.052 2.571 0 1.51 1.051 2.571 1.052 1.06 2.58 1.06"
    ></path>
  </svg>
);

export default SvgIcon;
