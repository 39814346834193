import axios from '../axiosConfig';
import { ApiResponse } from '../interfaces';

export interface GetUserData {
  id?: string;
  email?: string;
  userName?: string;
  country?: string;
  description?: string;
  address?: string;
  createdAt?: string;
  status?: string;
  imageUrl?: string;
}

export interface GetUsersResponse extends ApiResponse {
  data: GetUserData
}

export const getMyDataApi = async (): Promise<GetUsersResponse> => {
  let url = `/api/User/my-data/`    
  
  try
  {
      const response = await axios.get(url);
      return response.data;
  }
  catch (err: any)
  {
    return err.response.data;
  }

};
