interface LoginNavbarButtonProps {
    onClick: () => void;
    text: string,
    Icon: React.FC
}

const LoginNavbarButton: React.FC<LoginNavbarButtonProps> = ({onClick, text, Icon}) => {

    return (
        <button onClick={onClick} aria-label={text} className="border-0 cursor-pointer bg-[#CBC8C8] hover:bg-gray-300 rounded-lg flex items-center justify-center text-[#383838] text-[18px] py-3 font-medium gap-2">
            <Icon />
            <span className="hidden md:inline">{text}</span>
        </button>
    )
}

export default LoginNavbarButton;

