import CountryIcon from "@/assets/CountryIcon";
import { useRouter } from "next/router";

const CountrySwitcher: React.FC = () => {
    const router = useRouter();
    const { lang } = router.query;

    // const currentLang: SupportedLang =
    // typeof lang === 'string' && supportedLangs.includes(lang as SupportedLang)
    //   ? (lang as SupportedLang)
    //   : 'en';

    return (
        <div className="flex items-center justify-center gap-2 text-[#726C6C] font-bold border-r-2 border-solid border-l-0 border-[#C9C6C6] border-t-0 border-b-0 border-">
            {/* <CountryIcon />
            <p className="hidden md:block">Global (EN)</p>
            <svg width="12" height="8" viewBox="0 0 12 8" fill="none" className="hidden md:block mr-2" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.99735 7.38148C5.86198 7.38148 5.73599 7.35987 5.61936 7.31667C5.50272 7.27346 5.39184 7.1993 5.28673 7.09419L0.238798 2.04626C0.0832722 1.89075 0.00371196 1.69528 0.000117708 1.45984C-0.00349526 1.2244 0.076065 1.02532 0.238798 0.862601C0.401513 0.699886 0.598785 0.61853 0.830614 0.61853C1.06244 0.61853 1.25971 0.699886 1.42243 0.862601L5.99735 5.43752L10.5723 0.862601C10.7278 0.707094 10.9233 0.627533 11.1587 0.62392C11.3941 0.620326 11.5932 0.699886 11.7559 0.862601C11.9186 1.02532 12 1.2226 12 1.45445C12 1.68627 11.9186 1.88355 11.7559 2.04626L6.70797 7.09419C6.60286 7.1993 6.49198 7.27346 6.37534 7.31667C6.25871 7.35987 6.13271 7.38148 5.99735 7.38148Z" fill="#726C6C"/>
            </svg> */}
            <div className="">
              <span className={`fi fi-${lang} text-[30px] border border-solid mr-2`}></span>
            </div>
            <p className="hidden md:block pr-4">{typeof lang === 'string' ? lang.toUpperCase() : 'EN'}</p>

        </div>
    )
}

export default CountrySwitcher;