import * as React from "react";

const SvgIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    fill="none"
    viewBox="0 0 40 40"
  >
    <path
      fill="#4988CE"
      fillRule="evenodd"
      d="M24.478 11.061a1.917 1.917 0 1 1 2.71 2.711 1.917 1.917 0 0 1-2.71-2.711M25.833 8.5a3.917 3.917 0 0 0-3.868 4.529l-5.455 3.818-.01.007a3.917 3.917 0 1 0 0 6.292l.01.007 5.455 3.818a3.91 3.91 0 0 0 1.099 3.382 3.917 3.917 0 1 0-.269-5.242L17.75 21.58a3.92 3.92 0 0 0 0-3.16l5.045-3.531q.126.154.269.297a3.917 3.917 0 1 0 2.77-6.686m-1.355 17.728a1.917 1.917 0 1 1 2.71 2.71 1.917 1.917 0 0 1-2.71-2.71m-10.312-8.145a1.917 1.917 0 1 0 0 3.834 1.917 1.917 0 0 0 0-3.834"
      clipRule="evenodd"
    ></path>
  </svg>
);

export default SvgIcon;
