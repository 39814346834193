import { getSearchApi, GetSearchResponse, SearchData } from '@/utils/search/search';
import { useRouter } from 'next/router';
import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';

const SearchInput: React.FC = () => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const router = useRouter();
  // Stan na przechowywanie wyników wyszukiwania
  const [searchResults, setSearchResults] = useState<SearchData[]>([]);
  // Ref do kontenera wyszukiwania w widoku desktopowym
  const desktopContainerRef = useRef<HTMLDivElement>(null);

  const submitHandler = async (
    e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();

    if (searchValue.trim() !== '') {
      try {
        // Wywołanie endpointu
        const result = await getSearchApi(searchValue);
        // Zakładamy, że w result.data mamy już tablicę maks. 8 elementów
        setSearchResults(result.data);
      } catch (err) {
        console.error(err);
        setSearchResults([]);
      }

      // Zamykamy popup po wyszukiwaniu (mobile)
      // setIsPopupOpen(false);
    } else {
      // Jeśli input jest pusty, czyścimy listę
      setSearchResults([]);
    }
  };

  // Hook, który nasłuchuje kliknięć poza kontenerem w widoku desktopowym
  useEffect(() => {
    
    function handleClickOutside(event: MouseEvent) {
      const mediaQuery = window.matchMedia("(min-width: 768px)");
      if (!mediaQuery.matches) return;
      if (
        desktopContainerRef.current &&
        !desktopContainerRef.current.contains(event.target as Node)
      ) {
        setSearchResults([]);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      {/* Desktop: Pasek wyszukiwania widoczny na stałe */}
      <div ref={desktopContainerRef} className="hidden md:flex items-center w-full relative">
        <input
          type="text"
          placeholder="Szukaj..."
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          className="w-full py-2 pl-4 pr-10 text-lg text-gray-700 placeholder-gray-500 border-0 rounded-lg bg-gray-200 focus:outline-none"
        />
        <button
          onClick={submitHandler}
          className="absolute right-2 top-3 text-gray-500 bg-transparent border-0 cursor-pointer hover:text-gray-700"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-5 h-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M21 21l-4.35-4.35m0 0a7.5 7.5 0 10-10.6 0 7.5 7.5 0 0010.6 0z"
            />
          </svg>
        </button>

        {/* Lista wyników – pojawia się pod inputem, jeśli są wyniki */}
        {searchResults.length > 0 && (
          <ul className="absolute pl-1 top-full left-0 w-full bg-white border border-gray-200 rounded-lg shadow-md mt-1 z-10">
            {searchResults.map((item) => (
              <li
                key={Date.now() + item.id}
                className="p-2 hover:bg-gray-100 cursor-pointer flex items-center gap-2"
                onClick={() => {
                  setSearchResults([]);
                  router.push(`/profile/${item.id}`);
                }}
              >
                {item.imageUrl && (
                  <img
                    src={item.imageUrl}
                    alt={item.name}
                    className="w-6 h-6 object-cover rounded-full"
                  />
                )}
                <span>{item.name}</span>
              </li>
            ))}
          </ul>
        )}
      </div>

      {/* Mobile: Ikona wyszukiwania */}
      <div className="flex md:hidden">
        <button
          onClick={() => setIsPopupOpen(true)}
          className="text-gray-500 bg-transparent border-0 cursor-pointer hover:text-gray-700"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-6 h-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M21 21l-4.35-4.35m0 0a7.5 7.5 0 10-10.6 0 7.5 7.5 0 0010.6 0z"
            />
          </svg>
        </button>
      </div>

      {/* Portal z popupem – renderowany nad wszystkim (mobile) */}
      {isPopupOpen &&
        ReactDOM.createPortal(
          <div className="fixed top-0 left-0 w-screen h-screen z-[99999] flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white w-11/12 max-w-md p-4 rounded-lg mx-6">
              <div className="flex items-center justify-between mb-4">
                <input
                  type="text"
                  placeholder="Szukaj..."
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  className="flex-1 border-solid py-2 pl-4 pr-10 text-lg text-gray-700 placeholder-gray-500 border border-gray-300 rounded-lg focus:outline-none"
                />
                <button
                  onClick={() => setIsPopupOpen(false)}
                  className="ml-4 border-0 rounded-full flex items-center justify-center p-2 text-black hover:text-gray-800"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>

              {/* Lista wyników wewnątrz popupu (mobile) */}
              {searchResults.length > 0 && (
                <ul className="mb-4 border border-gray-300 rounded-lg">
                  {searchResults.map((item, index) => (
                    <li
                      key={Date.now() + index}
                      className="p-2 hover:bg-gray-100 cursor-pointer flex items-center gap-2"
                      onClick={() => {
                        setIsPopupOpen(false);
                        router.push(`/profile/${item.id}`);
                      }}
                      
                    >
                      {item.imageUrl && (
                        <img
                          src={item.imageUrl}
                          alt={item.name}
                          className="w-6 h-6 object-cover rounded-full"
                        />
                      )}
                      <span>{item.name}</span>
                    </li>
                  ))}
                </ul>
              )}


              <button
                onClick={submitHandler}
                className="w-full py-2 border-0 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
              >
                Szukaj
              </button>
            </div>
          </div>,
          document.body
        )}
    </>
  );
};

export default SearchInput;
