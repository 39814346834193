import LogoFooter from "@/assets/LogoFooter";
import MessageIcon from "@/assets/MessageIcon";
import PhoneIcon from "@/assets/PhoneIcon";
import VideoIcon from "@/assets/VideoIcon";
import avatarPhoto from "@/assets/avatarPhoto.webp";
import Image from "next/image";

function Footer() {
  return (
      <footer className="flex flex-col lg:flex-row gap-9 justify-between box-border p-10 border-t border-solid border-x-0 border-b-0 bg-white border-[#726C6C] text-center lg:text-start">
        <aside>
         <LogoFooter />
          <p className="text-[18px] text-[#726C6C] lg:w-1/2">
            Business-comunicator łączy ludzi biznesu na całym świecie.
          </p>
        </aside>
        <nav className="text-[22px] leading-10 flex flex-col">
          <p className="text-[#090808] my-1 text-[22px]">Szybkie linki</p>
          <a href="/home" className="link link-hover text-[#726C6C]">Home</a>
          <a href="/city/cit-1507" className="link link-hover text-[#726C6C]">Miasto</a>
          <a href="/home" className="link link-hover text-[#726C6C]">Kategorie</a>
          <a href="/pricing" className="link link-hover text-[#726C6C]">Abonamenty</a>
        </nav>
        <nav className="text-[22px] leading-10 flex flex-col">
          <p className="text-[#090808] my-1 text-[22px]">Zasoby</p>
          <a href="/regulations" className="link link-hover text-[#726C6C]">Regulations</a>
          <a href="/policy" className="link link-hover text-[#726C6C]">Policy</a>
          <a href="/policy" className="link link-hover text-[#726C6C]">Price</a>
          {/* <a href="/" className="link link-hover text-[#726C6C]">Podatki i opłaty</a> */}
        </nav>
        <aside>
          {/* <div className="text-center">
            <img
             src={avatarPhoto.src} alt="customer image" width={50} height={50} className="w-1/2 h-1/2"/>
          </div>
          <p className="text-[20px] text-[#090808] text-center w-full mt-0 mb-1">Adam Kowalski</p>
          <p className="text-[20px] text-[#008000] text-center w-full mt-0">Online</p>
          <div className="w-full flex justify-center items-center gap-8">
            <span className="bg-blue-600 p-3 rounded-full flex justify-center items-center">
              <MessageIcon />
            </span>
            <span className="bg-green-600 p-3 rounded-full flex justify-center items-center"><PhoneIcon/></span>
            <span className="bg-red-700 p-3 rounded-full flex justify-center items-center"><VideoIcon/></span>
          </div> */}
        </aside>
      </footer>
  );
}

export default Footer;
