import * as React from "react";

const SvgIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    fill="none"
    viewBox="0 0 40 40"
  >
    <path
      fill="#4988CE"
      d="M13.947 23.16h7.264a.88.88 0 0 0 .647-.26.88.88 0 0 0 .26-.647.88.88 0 0 0-.26-.647.88.88 0 0 0-.648-.26h-7.263a.88.88 0 0 0-.647.26.88.88 0 0 0-.26.647q0 .387.26.647.261.26.647.26m0-3.63h12.106a.88.88 0 0 0 .647-.262.88.88 0 0 0 .26-.647.88.88 0 0 0-.26-.647.88.88 0 0 0-.647-.26H13.947a.88.88 0 0 0-.647.26.88.88 0 0 0-.26.647q0 .387.26.648.261.26.647.26m0-3.632h12.106a.88.88 0 0 0 .647-.261.88.88 0 0 0 .26-.647.88.88 0 0 0-.26-.647.88.88 0 0 0-.647-.261H13.947a.88.88 0 0 0-.647.26.88.88 0 0 0-.26.648q0 .386.26.647.261.26.647.26M12.783 27.7l-2.423 2.424q-.516.516-1.188.234-.672-.284-.672-1.019V11.731q0-.918.636-1.553a2.11 2.11 0 0 1 1.552-.636h18.624q.917 0 1.552.636.636.636.636 1.553v13.781q0 .918-.636 1.553a2.11 2.11 0 0 1-1.552.635zm-.772-1.815h17.3a.36.36 0 0 0 .257-.117.36.36 0 0 0 .116-.256V11.731a.36.36 0 0 0-.116-.256.36.36 0 0 0-.256-.117H10.688a.36.36 0 0 0-.256.117.36.36 0 0 0-.116.256v15.83z"
    ></path>
  </svg>
);

export default SvgIcon;
