import axios from '../axiosConfig';
import { ApiResponse } from '../interfaces';

// Definiujemy parametry wyszukiwania
export interface GetSearchParams {
  query: string;
  searchType?: number; // 0, 1, 2 lub 3
}

export interface SearchData {
    id: string;
    name: string;
    imageUrl: string;
    type: number;
    }

// Definiujemy odpowiedź z endpointu wyszukiwania
export interface GetSearchResponse extends ApiResponse {
    data: SearchData[];
}

// Funkcja wywołująca GET /api/Search
export const getSearchApi = async (
  query: string
): Promise<GetSearchResponse> => {
  try {
    const response = await axios.get(`/api/Search?query=${query}`);
    return response.data;
  } catch (err: any) {
    console.log(err);
    return err.response.data;
  }
};
