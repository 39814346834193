import axios from 'axios';
import Cookies from 'js-cookie';
import Router from 'next/router'; // Router Next.js
// Utwórz instancję Axios
const axiosInstance = axios.create({
  //baseURL: 'http://localhost:5012', // URL API
  //baseURL: 'http://65.108.44.219:8082',
  baseURL:'https://api.business-communicator.com',
  withCredentials: true, // Pozwala na przesyłanie ciasteczek
});

// Interceptor żądań
axiosInstance.interceptors.request.use(
  (config) => {
    const token = Cookies.get('token'); // Pobierz token z ciasteczek
    if (token) {
      config.headers.Authorization = `Bearer ${token}`; // Dodaj nagłówek Bearer
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    // Jeśli odpowiedź jest poprawna, zwróć ją
    return response;
  },
  (error) => {
    // Obsługa błędu
    if (error.response?.status === 401) {
      // Usuń token z ciasteczek
      Cookies.remove('token');

      // Przekierowanie na stronę główną
      //Router.push('/');

      console.error('Nieautoryzowany dostęp. Przekierowanie na stronę główną.');
    }

    // Zwróć obietnicę z błędem, aby obsłużyć go dalej
    return Promise.reject(error);
  }
);

export default axiosInstance;
