import * as React from "react";

const SvgIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="41"
    height="40"
    fill="none"
    viewBox="0 0 41 40"
  >
    <path
      fill="#4988CE"
      d="M20.128 39.972c-4.806 0-9.612.014-14.418 0C2.89 39.96.716 38.217.128 35.524c-.087-.419-.1-.85-.1-1.282C.02 24.745.06 15.248 0 5.758-.02 2.727 2.477-.02 5.785 0c9.53.06 19.06.014 28.592.027 3.395 0 5.798 2.41 5.798 5.812.013 9.436 0 18.879.006 28.315 0 3.564-2.848 5.9-5.885 5.845-4.725-.094-9.45-.027-14.175-.027zM8.714 11.407c-.904 3.996-2.059 7.796-3.753 11.495l-2.328-.452v.911c0 3.523.067 7.047-.027 10.563-.048 1.87 1.221 3.483 3.435 3.463 9.362-.06 18.73-.06 28.093 0 1.876.013 3.462-1.262 3.401-3.395-.094-3.382-.027-6.77-.027-10.158v-.837c-.303.06-.533.1-.864.168-2.186-3.597-4.353-7.195-5.973-11.292 2.329-1.195 4.55-2.335 6.844-3.51V5.845c-.02-2.031-1.188-3.206-3.22-3.206-2.942-.006-5.885 0-8.821 0H5.798c-1.654 0-2.956.959-3.105 2.477-.135 1.458-.027 2.943-.027 4.415 1.998.627 3.949 1.235 6.048 1.89z"
    ></path>
    <path
      fill="#4988CE"
      d="M24.245 23.496c1.977 1.93 4.185 3.59 6.473 5.09-.142 1.653-1.445 2.476-2.862 1.788-.716-.351-1.357-.864-2.012-1.337-.998-.729-1.977-1.491-2.97-2.234-.161-.121-.357-.202-.54-.303-.06.053-.114.108-.175.155.149.189.263.412.446.553 1.586 1.202 3.192 2.383 4.785 3.578.04.027.054.087.081.135-.358.762-1.221 1.08-2.301 1.012-1.384-.081-2.417-.81-3.443-1.613a22 22 0 0 0-1.91-1.377c.074.736 1.688 1.991 4.171 3.368-.985.695-2.753.263-3.59-.89-.19-.264-.297-.588-.507-.824-.324-.372-.702-.689-1.134-1.094.17-1.627-.79-2.551-2.457-2.207-.924-2.018-1.842-2.227-4.13-1.06-.56-.54-1.02-1.12-1.607-1.512-.688-.452-1.451-.27-2.092.19-1.87-.919-2.396-2.1-1.836-4.003.735-2.498 1.471-4.988 2.193-7.486.129-.432.196-.884.331-1.485 2.41.075 4.806.156 7.31.23-.371.33-.742.587-1.026.918-1.525 1.782-1.788 3.874-1.343 6.068.277 1.363 1.991 1.748 3.18.756.364-.304.634-.723.904-1.12.256-.379.438-.804.661-1.195.844-1.485 1.883-1.85 3.361-.959 1.026.621 1.93 1.451 2.856 2.227 2.153 1.796 4.286 3.605 6.419 5.414.35.297.688.6 1.012.925.682.688.803 1.248.418 1.856-.465.742-1.484 1.167-2.24.837a7.5 7.5 0 0 1-1.647-1.006c-1.344-1.08-2.646-2.207-3.97-3.307-.175-.149-.41-.23-.613-.345l-.183.264z"
    ></path>
    <path
      fill="#4988CE"
      d="M35.463 23.455c-.54.426-.992.878-1.532 1.188-.54.324-1.093.142-1.566-.263-2.49-2.14-4.961-4.306-7.485-6.405-.945-.783-1.965-1.52-3.051-2.08-1.303-.674-2.309-.31-3.126.912-.37.553-.607 1.215-1.012 1.735-.385.492-.857.985-1.397 1.275-.776.412-1.39.088-1.667-.695-.797-2.275.364-5.467 2.436-6.675a59 59 0 0 1 2.484-1.35c.965-.5 1.985-.743 3.044-.392 1.81.608 3.672.803 5.555.972 1.094.101 1.944.628 2.444 1.62a779 779 0 0 1 3.76 7.492c.398.817.708 1.674 1.113 2.66zM14.276 25.872c.398.31.89.553 1.174.951.344.466.23 1.047-.148 1.492-.44.513-.885 1.02-1.398 1.451-.762.655-1.437.642-2.004.061-.723-.736-.878-1.816-.365-2.497.493-.648 1.81-1.303 2.74-1.458M18.353 28.875c-.156.932-1.39 2.586-2.174 2.815-.52.155-1.539-.21-1.795-.675-.324-.587.006-1.02.398-1.424.5-.527.979-1.073 1.505-1.573.378-.358.884-.52 1.323-.169.317.257.5.675.742 1.02zM18.906 30.26c.668.816 1.37 1.673 2.147 2.625-.601.148-1.202.371-1.823.425-.958.081-1.451-.317-1.593-1.1-.148-.864.324-1.647 1.27-1.95M10.111 27.573c-.729 0-1.478-.81-1.451-1.566.02-.594.452-1.026 1.026-1.026.797 0 1.708.783 1.688 1.451-.014.567-.655 1.141-1.27 1.141z"
    ></path>
  </svg>
);

export default SvgIcon;
